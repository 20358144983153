import { Grid, Typography } from "@material-ui/core";
import React from "react";

const Landing = () => (
  <Grid>
    <Typography style={{ textAlign: "center" }} variant="h4">
      Welcome to the start of something great!
    </Typography>
  </Grid>
);

export default Landing;
