import React from "react";
import { Link } from "react-router-dom";

import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { AuthUserContext } from "../Session";
import MenuIcon from "@material-ui/icons/HomeOutlined";
import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  bar: {
    padding: "0 10px",
    backgroundColor: "white"
  },
  buttonGrid: {
    alignItems: "center"
  },
  buttons: {
    marginLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  link: {
    textDecoration: "none",
    color: "black"
  }
}));

const Navigation = () => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <AppBar className={classes.bar} position="static">
        <Toolbar>
          <Grid
            className={classes.buttonGrid}
            justify="space-between"
            container
            spacing={5}
          >
            <Grid item>
              <Link className={classes.link} to={ROUTES.LANDING}>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
              </Link>
            </Grid>
            <Grid item>
              <Link className={classes.link} to={ROUTES.HOME}>
                Home
              </Link>
            </Grid>
            <Grid item>
              <Link className={classes.link} to={ROUTES.ACCOUNT}>
                Account
              </Link>
            </Grid>
            {!!authUser.roles[ROLES.ADMIN] && (
              <Grid item>
                <Link className={classes.link} to={ROUTES.ADMIN}>
                  Admin
                </Link>
              </Grid>
            )}
            <Grid item>
              <SignOutButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Fragment>
  );
};

const NavigationNonAuth = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <AppBar className={classes.bar} position="static">
        <Toolbar>
          <Grid
            className={classes.buttonGrid}
            justify="space-between"
            container
            spacing={5}
          >
            <Link className={classes.link} to={ROUTES.LANDING}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            </Link>
            <Typography variant="h6" className={classes.title}></Typography>
            <Grid item>
              <Link className={classes.link} to={ROUTES.SIGN_IN}>
                SIGN IN
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Fragment>
  );
};

export default Navigation;
