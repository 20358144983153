import React from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";

const SignOutButton = ({ firebase }) => (
  <Link
    style={{ textDecoration: "none", color: "black" }}
    type="button"
    onClick={firebase.doSignOut}
  >
    SIGN OUT
  </Link>
);

export default withFirebase(SignOutButton);
