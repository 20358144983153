import React, { Component, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { animated, useSpring } from "react-spring";

import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import {
  ERROR_CODE_ACCOUNT_EXISTS_SIGNIN,
  ERROR_MSG_ACCOUNT_EXISTS_SIGNIN
} from "../../constants/errors";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { ReactComponent as FacebookIcon } from "./../../Icons/facebook.svg";
import { ReactComponent as GoogleIcon } from "./../../Icons/google.svg";
import { KeyboardBackspace } from "@material-ui/icons";
import { SignUpForm } from "../SignUp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "75px auto",
    textAlign: "center"
  },
  divider: {
    textAlign: "-webkit-center"
  },
  verticalDividerGrid: {
    textAlign: "-webkit-center",
    margin: "-5px",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  verticalDivider: {
    margin: "10px",
    height: "50px"
  },
  horizontalDividerGrid: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  horizontalDivider: {
    margin: "25px 10px"
  },
  horizontalDividerText: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  gridItem: { textAlign: "-webkit-center" },
  link: {
    textDecoration: "none",
    color: "black"
  },
  form: {
    width: "30ch"
  }
}));

const Boop = ({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  timing = 150,
  children
}) => {
  const [isBooped, setIsBooped] = React.useState(false);
  const style = useSpring({
    display: "inline-block",
    backfaceVisibility: "hidden",
    transform: isBooped
      ? `translate(${x}px, ${y}px)
       rotate(${rotation}deg)
       scale(${scale})`
      : `translate(0px, 0px)
       rotate(0deg)
       scale(1)`,
    config: {
      tension: 300,
      friction: 10
    }
  });
  React.useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);
  const trigger = () => {
    setIsBooped(true);
  };
  return (
    <animated.span onMouseEnter={trigger} style={style}>
      {children}
    </animated.span>
  );
};

const SignInPage = () => {
  const classes = useStyles();
  const [isCreateAccount, setIsCreateAccount] = useState(false);
  const [buttonText, setButtonText] = useState("Create an account");
  const handleClick = () => {
    setIsCreateAccount(!isCreateAccount);
    isCreateAccount
      ? setButtonText("Create an account")
      : setButtonText("Sign In");
  };
  return (
    <div style={{ margin: "25px auto" }}>
      <Grid container justify="space-between" style={{ padding: "0 30px" }}>
        <Grid item style={{ visibility: isCreateAccount && "hidden" }}>
          <Boop rotation={45} timing={150}>
            <Link className={classes.link} to={ROUTES.LANDING}>
              <KeyboardBackspace style={{ fontSize: "30px", color: "black" }} />
            </Link>
          </Boop>
        </Grid>
        <Grid item style={{ alignSelf: "center" }}>
          <Boop rotation={10} timing={200}>
            <Typography
              type="button"
              style={{ cursor: "pointer" }}
              onClick={() => handleClick()}
              variant="subtitle2"
            >
              {buttonText}
            </Typography>
          </Boop>
        </Grid>
      </Grid>
      {isCreateAccount ? (
        <div className={classes.root}>
          <SignUpForm />
        </div>
      ) : (
        <div className={classes.root}>
          <Typography
            variant="h5"
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            Sign in to Chariteam
          </Typography>
          <Grid
            container
            justify="center"
            style={{
              maxWidth: "700px",
              alignItems: "center",
              margin: "0 auto"
            }}
          >
            <Grid item xs={12} sm className={classes.gridItem}>
              <SignInForm classes={classes} />
            </Grid>
            <Grid item sm={1} className={classes.verticalDividerGrid}>
              <Divider
                className={classes.verticalDivider}
                orientation="vertical"
              />
              <Typography variant="caption">OR</Typography>
              <Divider
                className={classes.verticalDivider}
                orientation="vertical"
              />
            </Grid>
            <Grid item xs={5} className={classes.horizontalDividerGrid}>
              <Divider
                className={classes.horizontalDivider}
                orientation="horizontal"
              />
            </Grid>
            <Grid item xs={1} className={classes.horizontalDividerText}>
              <Typography variant="caption">OR</Typography>
            </Grid>
            <Grid item xs={5} className={classes.horizontalDividerGrid}>
              <Divider
                className={classes.horizontalDivider}
                orientation="horizontal"
              />
            </Grid>
            <Grid item xs={12} sm className={classes.gridItem}>
              <SignInGoogle />
              <SignInFacebook />
            </Grid>
            <Grid
              style={{ marginTop: "10px" }}
              item
              xs={12}
              className={classes.gridItem}
            >
              <PasswordForgetLink />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <form className={this.props.classes.form} onSubmit={this.onSubmit}>
        <TextField
          style={{ marginBottom: "15px", width: "270px" }}
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <TextField
          style={{ marginBottom: "25px", width: "270px" }}
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <Button variant="outlined" fullWidth disabled={isInvalid} type="submit">
          Sign In
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return (
          socialAuthUser.additionalUserInfo.isNewUser &&
          this.props.firebase.user(socialAuthUser.user.uid).set({
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            roles: []
          })
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS_SIGNIN) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS_SIGNIN;
        }
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit} style={{ marginBottom: "10px" }}>
        <Button variant="outlined" type="submit" style={{ width: "90%" }}>
          <GoogleIcon style={{ width: "40px", height: "40px" }} />
          <h5 style={{ width: "200px" }}>Continue with Google</h5>
        </Button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return (
          socialAuthUser.additionalUserInfo.isNewUser &&
          this.props.firebase.user(socialAuthUser.user.uid).set({
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            roles: []
          })
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS_SIGNIN) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS_SIGNIN;
        }
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button variant="outlined" type="submit" style={{ width: "90%" }}>
          <FacebookIcon style={{ width: "40px", height: "40px" }} />
          <h5 style={{ width: "200px" }}>Continue with Facebook</h5>
        </Button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);
const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);
const SignInFacebook = compose(withRouter, withFirebase)(SignInFacebookBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook };
