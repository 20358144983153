import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";

import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import {
  ERROR_MSG_ACCOUNT_EXISTS_SIGNUP,
  ERROR_CODE_ACCOUNT_EXISTS_SIGNUP
} from "../../constants/errors";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { SignInFacebook, SignInGoogle } from "../SignIn";
import { MailOutline } from "@material-ui/icons";
import { Fragment } from "react";

const SignUpPage = () => (
  <div>
    <h1>SignUp</h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { isEmail: false, ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create a user in Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          roles
        });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS_SIGNUP) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS_SIGNUP;
        }
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  SignUpWithEmail = () => (
    <Button
      variant="outlined"
      onClick={() => this.setState({ isEmail: true })}
      style={{ width: "90%", marginTop: "10px" }}
    >
      <MailOutline style={{ width: "40px", height: "30px" }} />
      <h5 style={{ width: "200px" }}>Continue with Email</h5>
    </Button>
  );

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error
    } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";
    return (
      <Fragment>
        <Grid
          justify="center"
          container
          spacing={3}
          style={{ width: "300px", margin: "0 auto" }}
        >
          <Typography style={{ margin: "15px" }} variant="h5">
            Create your account
          </Typography>

          <Grid item xs={12}>
            <SignInGoogle />
            <SignInFacebook />
            <this.SignUpWithEmail />
          </Grid>
        </Grid>
        {this.state.isEmail && (
          <form onSubmit={this.onSubmit}>
            <Grid
              justify="center"
              container
              spacing={3}
              style={{ width: "300px", margin: "0 auto" }}
            >
              <Grid item xs={12}>
                <TextField
                  name="username"
                  value={username}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Username"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Email Address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="passwordOne"
                  value={passwordOne}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Confirm Password"
                />
              </Grid>
              {/* <label>
          Admin:
          <input
            name="isAdmin"
            type="checkbox"
            checked={isAdmin}
            onChange={this.onChangeCheckbox}
          />
        </label> */}
              <Grid item>
                <Button
                  style={{ width: "216px" }}
                  variant="outlined"
                  disabled={isInvalid}
                  type="submit"
                >
                  Sign Up
                </Button>
              </Grid>
              {error && <p>{error.message}</p>}
            </Grid>
          </form>
        )}
      </Fragment>
    );
  }
}

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm };
